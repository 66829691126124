/** @jsx jsx */
import PropTypes from 'prop-types'
import { jsx } from '@emotion/core'
import { typeRamp, media } from '../../constants'
import { withAccentTheme } from '../../context/AccentThemeContext'
import { GRID_GUTTER_WIDTH } from '../Grid'
import { Children } from 'react'

const propTypes = {
	children: PropTypes.node,
}

const defaultProps = {
	children: [],
}

const OrderedHighlightList = ({ children }) => {
	return (
		<ol
			data-comp="ordered-highlight-list"
			css={{
				listStyleType: 'none',
				padding: 0,
				margin: 0,
			}}
		>
			{Children.map(children, (child, idx) => {
				return (
					<li
						key={idx}
						css={{
							listStyleType: 'none',
							padding: '4rem 0',
							margin: 0,
							borderTop: '1px #E6E6E6 solid',
							display: 'flex',
							justifyContent: 'space-between',
							'&:last-child': {
								[media.small]: {
									paddingBottom: 0,
								},
							},
						}}
					>
						<div
							css={{
								...typeRamp.light_16,
								marginRight: `${GRID_GUTTER_WIDTH * 2}%`,
								[media.mediumUp]: {
									...typeRamp.light_20,
								},
							}}
						>
							{(idx + 1).toString().padStart(2, 0)}
						</div>
						<div
							css={{
								flex: '1',
								[media.mediumUp]: {
									width: '60%',
									flex: '0 0 60%',
								},
							}}
						>
							{child}
						</div>
					</li>
				)
			})}
		</ol>
	)
}

OrderedHighlightList.propTypes = propTypes
OrderedHighlightList.defaultProps = defaultProps

export default OrderedHighlightList

export const OrderedHighlightListItem = withAccentTheme(
	({ children, title }) => {
		return (
			<div data-comp="highlight">
				<h4
					css={{
						...typeRamp.med_16,
						marginBottom: '0.5rem',

						[media.mediumUp]: {
							...typeRamp.med_20,
						},
					}}
				>
					{title}
				</h4>

				{children}
			</div>
		)
	}
)
