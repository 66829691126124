/** @jsx jsx */
import { jsx } from '@emotion/core'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image/withIEPolyfill'

import Helmet from '../components/HelmetHelper'
import Container from '../components/Container'
import { CopyButtonInline } from '../components/CopyButton'
import Text, { TEXT_SIZE_SMALL } from '../components/content/Text'
import { THEME_GREEN } from '../constants'
import Slide, { SLIDE_LAYOUT_MOCK_FULL_SCREEN } from '../components/Slide'
import {
	LayoutIndented,
	LAYOUT_V_CADENCE_SIZE_MEDIUM,
	LayoutVerticalCadence,
	LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE,
} from '../components/content/Layout'
import { provideAccentTheme } from '../context/AccentThemeContext'
import OrderedHighlightList, {
	OrderedHighlightListItem,
} from '../components/content/OrderedHighlightList'

const ApproachPage = ({ accentTheme }) => {
	const data = useStaticQuery(graphql`
		query {
			collage: file(relativePath: { eq: "approach-collage@2x.jpg" }) {
				...ImageFluidFull
			}
		}
	`)

	return (
		<div>
			<Helmet>
				<title>Approach</title>
				<meta content="Approach | Free Association" property="og:title" />
				<meta content="Approach | Free Association" name="twitter:title" />
				<meta
					name="description"
					content="We collaborate closely with client partners to uncover insights, validate thinking, and learn as one team."
				/>
				<meta
					property="og:description"
					content="We collaborate closely with client partners to uncover insights, validate thinking, and learn as one team."
				/>
			</Helmet>
			<Slide layout={SLIDE_LAYOUT_MOCK_FULL_SCREEN}>
				<Container>
					<LayoutIndented>
						<Text>
							<h1 className="--headline">All the wiser.</h1>
							<p>
								We collaborate closely with client partners to uncover insights,
								validate thinking, and learn as one team.
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
			<Slide>
				<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_MEDIUM}>
					<LayoutVerticalCadence size={LAYOUT_V_CADENCE_SIZE_EXTRA_LARGE}>
						<Container coastToCoastSmall={true}>
							<Img fluid={data.collage.childImageSharp.fluid} />
						</Container>
						<Container>
							<LayoutIndented>
								<Text>
									<p>
										Over the last decade, we’ve established tried-and-true
										principles that guide our approach.
									</p>
								</Text>
							</LayoutIndented>
						</Container>
					</LayoutVerticalCadence>
					<Container>
						<LayoutIndented>
							<OrderedHighlightList>
								<OrderedHighlightListItem title="Small teams with big impact.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											We assemble lean, cross-disciplinary teams made up of
											senior talent who know how to wrangle complexity and get
											to work. No unnecessary layers or oversized egos to slow
											us down.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Partners for the long run.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											The best products are never done. Investing in long-term
											partnerships allows our team to build deep understanding,
											identify opportunities, and deliver essential outcomes.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Our team is your team.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											Working towards a shared vision, we garner knowledge,
											learn from experiments, and celebrate wins—together. Our
											collective team works in lock-step, aligning early and
											often. No surprises, no big reveals.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Sleeves up, hands-on.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											We don't spin wheels or waste time on unnecessary work.
											Starting from strategic foundations, we create prototypes
											to test ideas, quickly getting to a tightly-honed product
											concept.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Flexible, fluid, adaptive.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											We don’t subscribe to rigid methods or believe in a
											miraculous process. We tailor our teams and approach to
											fit each partner’s unique vision and context — fine-tuning
											along the way as needed.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Evidence over assumptions.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											Products often fail because they chase the wrong problem.
											We continuously unearth customer needs and motivations
											through research and testing to ensure we’re building with
											fit and purpose.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Our practice is a product.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											Each engagement yields new learnings and better ways of
											working. We capture client feedback and use it to
											challenge our process and evolve our delivery. We believe
											there is always more to learn and improve.
										</p>
									</Text>
								</OrderedHighlightListItem>
								<OrderedHighlightListItem title="Systems for scale.">
									<Text defaultParagraph={TEXT_SIZE_SMALL}>
										<p className="--grey">
											The most elegant products are built upon elegant systems.
											We help teams drive efficiency and maintain quality
											through the creation of standards, design processes and
											system tools.
										</p>
									</Text>
								</OrderedHighlightListItem>
							</OrderedHighlightList>
						</LayoutIndented>
					</Container>
				</LayoutVerticalCadence>
			</Slide>
			<Slide prefferedTheme={accentTheme}>
				<Container>
					<LayoutIndented>
						<Text>
							<p className="--headline">Let’s work together.</p>
							<p className="--small">
								<CopyButtonInline
									copiedMessage="Copied"
									copyValue="hello@freeassociation.com"
								>
									hello@freeassociation.com
								</CopyButtonInline>
							</p>
						</Text>
					</LayoutIndented>
				</Container>
			</Slide>
		</div>
	)
}

export default provideAccentTheme(ApproachPage, THEME_GREEN)
